import {createSlice} from '@reduxjs/toolkit';

export const passRate = createSlice({
    name:'passRate',
    initialState:{data:{},seriesDropList:[],seriesLabels:[],districtNavs:[],stateNavs:[],centreNavs:[]},
    reducers:{
        setPassRateData:(state,action)=>{
            return{...state,
                   data:action.payload.examDetails}
        },
        setSeries:(state,action)=>{
            return{...state,
                   seriesDropList:action.payload.seriesDropList,
                   seriesLabels:action.payload.seriesLabels}
        },
        setDistrictNavs:(state,action)=>{
            return{...state,
                   districtNavs:action.payload.districtNavs
            }
        },
        setStateNavs:(state,action)=>{
            return{...state,
                stateNavs:action.payload.stateNavs
            }
        },
        setCentreNavs:(state,action)=>{
            return{...state,
                centreNavs:action.payload.centreNavs
            }
        }
    }
});

export const {setPassRateData,setSeries,setDistrictNavs,setStateNavs,setCentreNavs} = passRate.actions;
export default passRate.reducer;