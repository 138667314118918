import { Button, Card, Notification, Table, TableBody, TableContainer, TableHead, TableRow } from "@cambridgeassessment/cambridge-ui";
import { TableCell } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { constants } from "../../constants";
import { getdemographicData, getDistrictList, getSchoolsData, getSyllabusData } from "../../services/muleSoftServices";
import { useDispatch, useSelector } from "react-redux";
import { setDemographicData } from "../../redux/slice/demographics";
import { isEmpty } from "lodash";
import './Demographics.css';
import Spinner from "../../common/Spinner";
import Banner from "../../common/Banner";
import { setAlert } from "../../redux/slice/GlobalMessage";
import { useReactToPrint } from "react-to-print";
import { DropDown, GrpDropDown } from "../../common/Dropdown";
import { setSchoolsData, setSyllabusData } from "../../redux/slice/dropDownsData";
import { setTabNumber } from "../../redux/slice/sideNavigation";
import { useNavigate } from "react-router-dom";
import { showPendingNotification } from "../../common/validations";

const Demographics = () =>{
    const initialState ={spin:true,
                         centreDetails: {},
                         seriesSelectedValue:{},
                         syllabusSelectedValue:{},
                         qualificationSelectedValue:{},
                         districtSelectedValue:{},
                         schoolSelectedValue:{},
                         sort:{key:"sylID"}},
          [initialData,setInitialData] = useState(initialState),
          [genderData,setGenderData] = useState([]),
          [ethnicityData,setEthnicityData] = useState([]),
          [syllabusRes,setSyllabusRes] = useState([]),
          [disDropList,setDistDropList] = useState([]),
          [schoolRes,setSchoolRes] = useState([]),
          navigate = useNavigate(),
          initialized = useRef(false),
          initialSeries = useRef(false),
          notificationStatus = useRef("pending"),
          districtSelection = useRef(false),
          alert = useRef(""),
          seriesDropRef = useRef([]),
          savedPassResp = useSelector(state => state.pass),
          savedResps = useSelector(state => state.dropDowns),
          dispatch = useDispatch(),
          genderRef = useRef(null),
          ethnicityRef = useRef(null),
          spinnerRef = useRef(),
          downloadBtn = useRef(""),
          userInfo = useSelector((state) => state.userInfo),
          supportRole = useSelector((state) => state.new);                     

    useEffect(()=>{
        window.scrollTo(0,0);
        const {demographics} = constants;
        let firstSeries;
        if(!isEmpty(savedPassResp.seriesDropList)){
            seriesDropRef.current = savedPassResp.seriesDropList;
            firstSeries = savedPassResp.seriesDropList?.filter(s=>s.label === savedPassResp.seriesLabels[0]);
            setInitialData((previousState)=>{
                return{...previousState,
                        seriesSelectedValue:firstSeries[0],
                        qualificationSelectedValue:constants.dropDownList.qualification[0]}
            })            
        }        
        if(!initialized.current){
            initialized.current = true;
            if((userInfo.roleLevel === "exams" || userInfo.emlCentre || userInfo.roleLevel === "head")){
                retrieveSyllabus(firstSeries,constants.dropDownList.qualification[0]);                           
            }
            if(userInfo.roleLevel === "state" || userInfo.emlState){
                getDistrictData();
                downloadBtn.current = "Go to data downloads";                
            }
            if(userInfo.roleLevel !== "teacher"){
                downloadBtn.current = "Go to data downloads";
                alert.current = demographics[userInfo.roleLevel]?demographics[userInfo.roleLevel]:demographics.common;
            }          
            if(userInfo.roleLevel === 'district' || userInfo.emlDistrict){
                retrieveSchools(supportRole.support?userInfo.emlDistrictObj.districtId:userInfo.district);
                alert.current = demographics.district;
                downloadBtn.current = "Go to data downloads";
            }           
        }    
    // eslint-disable-next-line
    },[]);

    const getDistrictData = async() =>{        
      let modifiedParams;
        const {totalLoggedInData,roleFromSAP,roleLevel,hierarchy,
                emlStateObj,emlState,uid,orgs} = userInfo,
                {districtList} = constants.sideNav.roleBaseApiKeys;
            
            if(roleLevel === "support" && emlState){
                modifiedParams = {
                    roleName:emlStateObj.roleName,
                    stateId:emlStateObj.stateId
                }
            }

        const  params = {token:totalLoggedInData.access_token,
                        roleName:(supportRole.support && modifiedParams.roleName)?modifiedParams.roleName:roleFromSAP,
                        roleLevel:districtList[roleLevel],
                        bpId:orgs[0]?.bpid,
                        stateCode:(supportRole.support && modifiedParams.stateId)?modifiedParams.stateId:
                                 !isEmpty(hierarchy) && hierarchy[0]?.stateId},
            dList = await getDistrictList(params);
        if(dList?.data?.data){
        setDistDropList(dList.data.data);
        retrieveSchools(dList.data.data[0].districtID);
        spinnerRef.current?.spinnerOpen();        
        setInitialData((previousState)=>{
            return {...previousState,districtSelectedValue:dList.data.data[0],spin:false
        }})
        }
    }      

    const retrieveSchools = async (districtID) =>{ 
        let modifiedParams;              
        const {totalLoggedInData,roleFromSAP,roleLevel,hierarchy,
              emlDistrictObj,emlStateObj,uid,orgs,
              emlDistrict,emlState,stateName} = userInfo,
            {schoolList} = constants.sideNav.roleBaseApiKeys;
              
        if(roleLevel === "support" && emlDistrict){
            modifiedParams = {
                districtId:emlDistrictObj.districtId,
                roleLevel:emlDistrictObj.emlLevel
            }
        }else if(roleLevel === "support" && emlState){
            modifiedParams = {
                stateId:emlStateObj.stateCode
            }
        }

        const params = {token:totalLoggedInData.access_token,
                        roleName:roleFromSAP,
                        districtId:districtID,
                        roleLevel:schoolList[roleLevel],
                        bpId:orgs[0]?.bpid,
                        stateId:modifiedParams?.stateId?modifiedParams.stateId:
                                !isEmpty(hierarchy) && hierarchy[0]?.stateId},
            schoolData = await  getSchoolsData(params), 
            schoolArr = schoolData?.data?.data;
        if(!isEmpty(schoolArr)){
            setSchoolRes(schoolArr);
            if(schoolArr.length>0){
                spinnerRef.current?.spinnerOpen();
                setInitialData((previousState)=>{
                    return {...previousState,schoolSelectedValue:schoolArr[0],spin:false}
                })
            }else{
                spinnerRef.current?.spinnerOpen();
                setInitialData((previousState)=>{
                    return {...previousState,spin:false}
                })
            }
            if(districtSelection.current){
                districtSelection.current = false;
                hitDgData();
            }   
            dispatch(setSchoolsData({ schoolData:schoolArr}));       
        }          
    }

    useEffect(()=>{
        hitDgData();
    },[initialData.schoolSelectedValue,
       initialData.seriesSelectedValue ])

    
    const hitDgData = () =>{
        const {seriesSelectedValue,qualificationSelectedValue,
            schoolSelectedValue,districtSelectedValue} = initialData;

        if(!initialSeries.current && !isEmpty(schoolSelectedValue) &&
        !isEmpty(seriesSelectedValue) && !isEmpty(qualificationSelectedValue)){
        initialSeries.current = true;           
        let modifiedParams;

        const {totalLoggedInData,roleFromSAP,roleLevel,orgs,
                emlDistrictObj,emlStateObj,uid,hierarchy,state,
                emlDistrict,emlState} = userInfo,
                {ethinicityReport} = constants.sideNav.roleBaseApiKeys;
          
         if(roleLevel === "support" && emlDistrict){
            modifiedParams = {
              districtId:emlDistrictObj.districtId,
              roleLevel:emlDistrictObj.emlLevel
            }
        }
          
        const params = {token:totalLoggedInData.access_token,
                    roleName:roleFromSAP,
                    centreId:!isEmpty(schoolSelectedValue?.centreID)?schoolSelectedValue?.centreID:
                             orgs[0]?.sourceSystems[0]?.orgId ,
                    series:seriesSelectedValue?.label,
                    quals:qualificationSelectedValue?.label,
                    roleLevel:ethinicityReport[roleLevel],
                    uid:uid,
                    bpId:orgs[0]?.bpid,
                    stateId:!isEmpty(state)?state:
                            !isEmpty(hierarchy) && hierarchy[0]?.stateId,
                    districtId:districtSelectedValue?.districtID?districtSelectedValue.districtID:
                                modifiedParams?.districtId?modifiedParams.districtId:userInfo.district};
        for(let i=0;i<=1;i++){
            getDgData(params,i===0?"ethnicity":"gender");
        }
        }
    }
    

    const reqParams = (series,qual,syl,dist,centreId) =>{       
        let modifiedParams;

        const {totalLoggedInData,roleFromSAP,roleLevel,orgs,hierarchy,
                emlDistrictObj,emlStateObj,emlCentre,emlCentreObj,state,
                emlDistrict,emlState} = userInfo,
                {ethinicityReport} = constants.sideNav.roleBaseApiKeys;
        
        if(roleLevel === "support" && emlCentre){
            modifiedParams = {
                centreId:emlCentreObj.centreId
            }
        }else if(roleLevel === "support" && emlDistrict){
            modifiedParams = {
                districtId:emlDistrictObj.districtId,
                roleLevel:emlDistrictObj.emlLevel
            }
        }
        const params = {token:totalLoggedInData.access_token,
                        roleName:roleFromSAP,
                        centreId:!isEmpty(centreId)?centreId:
                                 modifiedParams?.centreId?modifiedParams.centreId:orgs[0]?.sourceSystems[0]?.orgId,
                        series:series[0].label,
                        quals:qual,
                        rLevel:roleLevel,
                        roleLevel:ethinicityReport[roleLevel],
                        syllabusId:syl,
                        bpId:orgs[0]?.bpid,
                        stateId:!isEmpty(state)?state:
                                !isEmpty(hierarchy) && hierarchy[0]?.stateId,
                        districtId:!isEmpty(dist)?dist:
                                   modifiedParams?.districtId?modifiedParams.districtId:
                                   userInfo.district};
        return params;
      }

    const retrieveSyllabus = async (series,qualification) =>{
        const {seriesSelectedValue} = initialData;        
        const syllabusData = await  getSyllabusData(reqParams(series?series:seriesSelectedValue,
                                                              qualification?.label,"","","" )),
              sortedSyllabuses = syllabusData?.data?.data.sort((a, b) => { 
                        return a[initialData.sort.key] - b[initialData.sort.key]; 
               });       
        setSyllabusRes(sortedSyllabuses);          
        if(!isEmpty(sortedSyllabuses)){
            sortedSyllabuses.unshift({sylID:"All syllabuses",sylName:"All syllabuses"});
            dispatch(setSyllabusData({syllabusData:sortedSyllabuses}));
            spinnerRef.current?.spinnerOpen();            
            setInitialData((previousState) => {
                return {
                    ...previousState,
                    spin:false,
                    syllabusSelectedValue:sortedSyllabuses[0],
                    seriesSelectedValue:series[0]
                };
            });
            const apiReq = reqParams(series,qualification?.label,sortedSyllabuses[0]?.sylID);
            for(let i=0;i<=1;i++){
                getDgData(apiReq,i===0?"ethnicity":"gender");
            }
        }else{
            spinnerRef.current?.spinnerOpen();
            setInitialData((previousState) => {
                return {
                  ...previousState,
                  spin:false,
                  seriesSelectedValue:series[0],
                  qualificationSelectedValue:constants.dropDownList.qualification[0]
                };
            });
            setEthnicityData([]);
            setGenderData([]);
        }
    }

    const getDgData = async(params,dgType) =>{        
        const res = await getdemographicData(params,dgType);
        if(!res?.data?.dataRows.length){
            notificationStatus.current = showPendingNotification(params.series);
        }
        if(dgType === "ethnicity"){
            setEthnicityData(res?.data?.dataRows);
        }else{
            setGenderData(res?.data?.dataRows);
            spinnerRef.current?.spinnerOpen();
            setInitialData((previousState)=>{
                return {...previousState,spin:false}
              })            
        }
    }   
    const genderPdf = useReactToPrint({
        bodyClass:"m-2 p-2",
        documentTitle:'Gender_Report',
        content: () => genderRef.current
      });
    
    const ethnicityPdf = useReactToPrint({
        bodyClass:"m-2 p-2",
        documentTitle:'Ethnicity_Report',
        content: () => ethnicityRef.current
    });

    const selectedOption = (key, li) => {
        let apiReq;
        initialSeries.current = false;
        const {seriesSelectedValue,
               qualificationSelectedValue,
               schoolSelectedValue,
               syllabusSelectedValue} = initialData;
        
        if(initialData[key]?.label && initialData[key].label === li?.label ||
           initialData[key]?.centreID && initialData[key].centreID === li?.centreID ||
           initialData[key]?.districtID && initialData[key].districtID === li?.districtID ||
           initialData[key]?.sylID && initialData[key].sylID === li?.sylID){
            return;
        }
      
        if(key === "syllabusSelectedValue"){            
            apiReq = reqParams([seriesSelectedValue],qualificationSelectedValue.label,li.sylID,"","");            
        }
        if(key === "qualificationSelectedValue"){
            apiReq = reqParams([seriesSelectedValue],li.label,syllabusSelectedValue?.sylID,"",schoolSelectedValue.centreID);                  
        }
        if(key === "seriesSelectedValue" && 
                (userInfo.roleLevel === "exams" || (supportRole.support && userInfo.emlCentre) || userInfo.roleLevel === "head")){
            retrieveSyllabus([li],initialData.qualificationSelectedValue);
        }
        if(key === "districtSelectedValue"){
            retrieveSchools(li.districtID);
        }
        setInitialData((previousState)=>{
            return {...previousState,[key]: li, spin:true}
        })
        if((key === "qualificationSelectedValue" || key === "syllabusSelectedValue") && userInfo.roleLevel !== "teacher"){           
            for(let i=0;i<=1;i++){
                getDgData(apiReq,i===0?"ethnicity":"gender");
            } 
        }                               
    };

    const tabChange = (s) => {
        if(s.category === "report"){
            dispatch(setTabNumber({managementTabNumber:-1,reportTabNumber:s.tab}));
        }else{
            dispatch(setTabNumber({managementTabNumber:s.tab,reportTabNumber:-1}));
        }        
        navigate(s.path);
    };

    useEffect(()=>{
        spinnerRef.current?.spinnerOpen();
    },[initialData.spin])

    return(
        <div>
        {initialData.spin ?<Spinner ref={spinnerRef} />:
        <>        
        <div ref={ethnicityRef}>
            <div className="row">
                <div className="txt">
                    <div className="col-lg-8 col-md-12 col-sm-12 text-start lbl">{constants.demographics.header}</div>
                    <div className={`col-lg-4 col-md-12 col-sm-12 txt demo_pdf ${window.innerWidth <821?"mt-2":""} `}>
                        <Button className={window.innerWidth <821?"float-start":"float-end"} onClick={() => tabChange({tab:0,path:"/dashboard",category:"report"})}>
                            {downloadBtn.current}
                        </Button>
                    </div>
                </div>
                {userInfo.roleLevel !== "state" && !userInfo.emlState && 
                <div className="col-lg-8 col-md-12 col-sm-12 mt-2"><p className="demo_sub_text text-start">{constants.demographics.subHeader}</p></div>}
                
                {(userInfo.roleLevel === "state" || userInfo.emlState) && 
                <div className="sub_text demo_sub_text text-start">{constants.demographics.stateSubHeader}</div>}              
            </div>

           

            <div className="row mb-4">
                {(userInfo.roleLevel === 'state' || (supportRole.support && userInfo.emlState)) && 
                <div className="col-lg-3 col-md-12 col-sm-12 mt-2">
                <DropDown class="text-start"
                        label='DISTRICT'
                        userSelectedDropdown='districtSelectedValue'
                        selectedValue={initialData.districtSelectedValue?.districtID}
                        list={disDropList.length?disDropList:[]}
                        onChange={selectedOption}
                        option='districtID' value='districtName'/>
                </div>}
                {((userInfo.roleLevel === 'state' || (supportRole.support && userInfo.emlState))  ||  
                  (userInfo.roleLevel === 'district' || (supportRole.support && userInfo.emlDistrict))) &&
                <div className="col-lg-3 col-md-12 col-sm-12 mt-2">
                <DropDown class="text-start"
                        label='SCHOOL'
                        userSelectedDropdown='schoolSelectedValue'
                        selectedValue={initialData.schoolSelectedValue?.centreID}
                        list={schoolRes.length?schoolRes:[]}
                        onChange={selectedOption}
                        option='centreID' value='centreName'
                        mixedStr={true}/>
                </div>}
                <div className="col-lg-3 col-md-12 col-sm-12 mt-2">
                <GrpDropDown class="text-start" 
                            onChange={selectedOption}
                            userSelectedDropdown='seriesSelectedValue'
                            label={constants.passRate.dropDownLabels.series}
                            selectedValue={initialData.seriesSelectedValue.label} 
                            list={seriesDropRef.current}
                            option='label' value='label' />
                </div>
                <div className="col-lg-3 col-md-12 col-sm-12 mt-2">
                <DropDown class="text-start" onChange={selectedOption}
                            userSelectedDropdown='qualificationSelectedValue'
                            selectedValue={initialData.qualificationSelectedValue.value}
                            label={constants.passRate.dropDownLabels.qual}
                            list={constants.dropDownList.qualification}
                            option='value' value='label' />
                </div>
                {(userInfo.roleLevel === "exams" || (supportRole.support && userInfo.emlCentre) || userInfo.roleLevel === "head")
                 && <div className="col-lg-6 col-md-12 col-sm-12 mt-2">
                <DropDown
                    class="text-start"
                    userSelectedDropdown='syllabusSelectedValue'
                    selectedValue={initialData.syllabusSelectedValue.sylID}
                    onChange={selectedOption}
                    label={constants.syllabusReports.dropDownLabels.qual}
                    list={syllabusRes}
                    mixedStr={true}
                    option='sylID' value='sylName'
                    />
                </div>}
                <div className="col-2"></div>
            </div>
            {ethnicityData?.length>0 && <Card className="card_spacing">
            <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12">
                    <h3 className="card-header text-start mb-2">Ethnicity / race report</h3>
                    <p className="mb-4 text-start card-sub-head">{initialData.seriesSelectedValue.label}</p>
                </div>
                <div className={`col-lg-6 col-md-12 col-sm-12 ${window.innerWidth <821?"text-start mb-4":"text-end"}`}>
                    <Button variant="outlined" startIcon={<svg  fill="#0076BD" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
                        <path d="M480-320 280-520l56-58 104 104v-326h80v326l104-104 56 58-200 200ZM240-160q-33 0-56.5-23.5T160-240v-120h80v120h480v-120h80v120q0 33-23.5 56.5T720-160H240Z"/></svg>} onClick={ethnicityPdf}>
                            Download (pdf)
                    </Button>
                </div>
            </div> 
            <TableContainer>
                    <Table aria-label="ethinicity data table">
                        <TableHead>                    
                            <TableRow className='h_bottom'>
                                {constants.demographics.ethinicHeaders.map((h,i)=>
                                    {return(<TableCell className={i == 2?'text-center grey_bg':i == 3?"head_bg_blue text-white text-center":""} key={i}>
                                                {h === "gte" ? <>&ge;E</> : h}
                                            </TableCell>)})
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                                {ethnicityData.length>0 && ethnicityData.map((r,i)=>{
                                    return(<TableRow hover={true} key={i}>
                                                <TableCell className="col-2">{r.ethnicity?constants.demographics.ethnicityCodes[r.ethnicity]:"No response"}</TableCell>
                                                <TableCell>
                                                <div className="w-auto h-auto col-8 progress p_bar_rad">
                                                    <div style={{ height: "100%", width: ((r.passrate<20 && r.passrate>0) || (userInfo.smallScreen && r.passrate>0)) ? 
                                                                                        "auto":`${r.passrate}%` }}
                                                        className="progress-bar head_bg_blue bar_text"
                                                        role="progressbar">
                                                        <span className="mx-1">{Number(r.passrate).toFixed(1)}%</span>
                                                    </div>
                                                </div>
                                                </TableCell>
                                                <TableCell className="text-center grey_bg col-1">{r.totalCount}</TableCell>
                                                <TableCell className="text-center val_bg_blue col-1">{Number(r.passrate).toFixed(1)}%</TableCell>                                            
                                            </TableRow>)
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Card>}
        </div>
        {genderData.length>0 && <div ref={genderRef}>
            <Card className="mt-5 card_spacing card-bg" style={{height:'auto'}}> 
            <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12">
                <h3 className="card-header text-start mb-2">
                Gender report
                </h3>
                <p className="mb-4 text-start card-sub-head">
                {initialData.seriesSelectedValue.label}
                </p>
                </div>
                <div className={`col-lg-6 col-md-12 col-sm-12 ${window.innerWidth <821?"text-start mb-4":"text-end"}`}>
                <Button variant="outlined" startIcon={<svg  fill="#0076BD" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M480-320 280-520l56-58 104 104v-326h80v326l104-104 56 58-200 200ZM240-160q-33 0-56.5-23.5T160-240v-120h80v120h480v-120h80v120q0 33-23.5 56.5T720-160H240Z"/></svg>} onClick={genderPdf}>Download (pdf)</Button>
                </div>
                </div>          
                <TableContainer>
                    <Table aria-label="demographics table">
                        <TableHead>                    
                            <TableRow className='h_bottom'>
                                {constants.demographics.tableHeaders.map((h,i)=>
                                    {return(<TableCell className={i == 2?'text-center grey_bg':i == 3?"text-center":""} key={i}>{h}</TableCell>)})
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                                {genderData.length>0 && genderData.map((r,i)=>{
                                    return(<TableRow hover={true} key={i}>
                                                <TableCell className="col-2">{r.gender?r.gender:"Not recorded"}</TableCell>
                                                <TableCell>
                                                <div className="w-auto h-auto col-8 progress p_bar_rad">
                                                    <div style={{ height: "100%", width: ((r.passrate<20 && r.passrate>0) || (userInfo.smallScreen && r.passrate>0)) ? 
                                                                                        "auto":`${r.passrate}%` }}
                                                        className="progress-bar head_bg_blue bar_text"
                                                        role="progressbar">
                                                        <span className="mx-1">{Number(r.passrate).toFixed(1)}%</span>
                                                    </div>
                                                </div>
                                                </TableCell>
                                                <TableCell className="text-center grey_bg col-1">{r.totalCount}</TableCell>
                                                <TableCell className="text-center col-1">{Number(r.passrate).toFixed(1)}%</TableCell>                                            
                                            </TableRow>)
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Card>
        </div>}
        {notificationStatus.current === "pending" && isEmpty(ethnicityData) && isEmpty(genderData) && userInfo.roleLevel !== 'teacher' && <Notification variant="info" className="mt-4">
        <p className="text-start"><strong>{initialData.seriesSelectedValue.label} exams results pending</strong></p><p className="text-start">View exam <a className="link" target="_blank" href="https://www.cambridgeinternational.org/usa/students/dates-and-deadlines/">results release dates.</a></p>
            <p className="text-start">You will be able to view demographics reports once results have been released.</p>
        </Notification>}

        {notificationStatus.current === "no data" && isEmpty(ethnicityData) && isEmpty(genderData) && userInfo.roleLevel !== 'teacher' &&
              <Notification variant="info" className="mt-4 text-start">Data not available for {initialData.seriesSelectedValue.label} for the selected options</Notification>
        }

        {isEmpty(ethnicityData) && isEmpty(genderData) && <Notification variant="warning" className="mt-4">
          <p className="text-start mb-2"><strong>Missing data</strong></p>
          {userInfo.roleLevel !== 'head' && <p className="text-start">We cannot provide demographic reports because student demographic data has not yet been added.</p>}
          {userInfo.roleLevel === 'head' && <p className="text-start lh-base">Student demographic data is missing. We can not provide detailed demographic reports for your school. Please contact your Exams Officer.</p>}
          {userInfo.roleLevel !== 'head' && 
           userInfo.roleLevel !== "district" && !userInfo.emlDistrict &&
           userInfo.roleLevel !== "state" && !userInfo.emlState &&
            <p style={{marginBottom:"10px;"}} className="text-start"><a className='custom_link' onClick={() => tabChange({tab:0,path:"/student-data",category:"management"})}>Upload student data</a></p>
          }
          {(userInfo.roleLevel === "district" || userInfo.emlDistrict) && <p style={{marginBottom:"10px;"}} className="text-start"><a className='custom_link' onClick={() => tabChange({tab:0,path:"/district-data",category:"management"})}>Upload your district data</a></p>}
        </Notification>}        
        </>}
        </div>
                        
    );
    
}
export default Demographics;